body {
  overflow-x: hidden;
}

/* Add this CSS to center the items in the headerToolbar */
.centered-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* :root {
  --fc-border-color: black;
  --fc-daygrid-event-dot-width: 5px;
} */

.sunday-cell {
  background-color: #f9fafd; /* Background color for Sundays */
}

.prev,
.customNext {
  background-color: transparent !important; /* Remove background color */
  color: #151515 !important; /* Set arrow color */
}

/* Center the title */
.customTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-button-primary {
  background-color: transparent;
  border-color: transparent;
  color: #151515;
}

.fc .fc-toolbar-title {
  font-size: 1.4rem;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f9f9f9;
}

a {
  white-space: nowrap;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  color: #ffffff;
  background-color: #024ea2; /* Set your desired background color */
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover {
  background-color: #024ea2; /* Set your desired background color */
}

.fc-event:hover,
.fc-event-start:hover,
.fc-event-end:hover,
.fc-event-today:hover,
.fc-daygrid-event:hover,
.fc-daygrid-dot-event:hover {
  background-color: inherit;
}

.pagination-button {
  color: primary;
  background-color: white;
  border: none;
  width: 33px;
  height: 33px;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
}

.pagination-button:hover {
  background-color: #f0f0f0;
  /* color: black; */
}

.selected-button {
  color: primary;
  background-color: #eff3f5; /* Set background color to #EFF3F5 */
  border: none;
  width: 33px;
  height: 33px;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 600;
}
